import React from 'react';

import Layout from '../components/common/Layout';
import Navbar from '../components/common/Navbar';

import Header from '../components/sections/Header';
import Body from '../components/sections/Body';
import Footer from '../components/sections/Footer';

//import 'bootstrap/dist/css/bootstrap.min.css';

const IndexPage = () => (
  <Layout>
    <Body />
  </Layout>
);

export default IndexPage;
