import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Button from '@material-ui/core/Button';

import { Section, Container, ImageCaption } from '@components/global';
import Map from '../common/Map';

const Body = () => (
  <StaticQuery
    query={graphql`
      query {
        imageTop: file(
          sourceInstanceName: { eq: "photos" }
          name: { eq: "RBHS_USAToday1b" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageBottom: file(
          sourceInstanceName: { eq: "photos" }
          name: { eq: "RBHS_USAToday8b" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760, maxHeight: 275) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="body">
        <Container>
          <Image>
            <Img fluid={data.imageTop.childImageSharp.fluid} />
            <ImageCaption>Photo Credit: Jasper Colt, USA Today</ImageCaption>
          </Image>
          <Mission>
            <p>The Foundation to Support the Young Kings, Inc., was established
            to champion the Ron Brown College Preparatory High School.
            RBHS is Washington, D.C.'s first all-male public high school.
            Together we are dedicated to empowering young men of color in the District of Columbia.
            </p>
            <Link to="/about">Learn More</Link>
          </Mission>
          <Grid>
            <div>
              <h2>Invest in the future of Young Kings.</h2>
              <p>
                Make a difference through education. 
              </p>
              <p>
                Contribute today. 
              </p>
            </div>
            
            <Button color="primary" variant="contained"><StyledLink to="/donate/">DONATE</StyledLink></Button>
            
          </Grid>
          <Grid inverse>
            <MapContainer>
              <Map
                isMarkerShown
                link="https://goo.gl/maps/KRTXpVf5gxZZa1vk9"
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC69rxlkDAxhS3cPlABeArkUhNYMaqiQ-E&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                latitude={38.907070}
                longitude={-76.932670}
              />
              <Caption>* Click pin for location details.</Caption>
            </MapContainer>
            
            <div>
              <h2>Event</h2>
              <h5>"Celebrating Three Years of Successfully Educating Young Men of Color"</h5>
              <div>Wednesday, June 5, 2019, 6:30 pm - 8:30 pm</div>
              <div>Ron Brown College Preparatory High School</div>
            </div>
          </Grid>
          <Image>
            <Img fluid={data.imageBottom.childImageSharp.fluid} />
            <ImageCaption>Photo Credit: Jasper Colt, USA Today</ImageCaption>
          </Image>
        </Container>
      </Section>
    )}
  />
);

const Mission = styled.div`
  padding: 50px; 
`;

const DonateButton = styled.div`
  font-size: 2.0rem;
  font-family: arial;
`;

const Caption = styled.span`
  display: inline;
  font-style: italic;
  font-size: 14px;
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 25px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;
  padding-bottom: 50px;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Image} {
          order: 2;
        }
    `}
  }
`;
const MapContainer = styled.div`
  position: relative;
  height: 250px;
  width: 100%;
  display: block;
`;

const Image = styled.figure`
  margin: 0;
  width: 100%;
`;

const StyledLink = styled(props => <Link {...props} />)`
  color: white;
  text-decoration: none;
  &:hover {
    color: inherit !important;
  }
`;

export default Body;
